<template>
  <v-container id="bills" class="pa-14">
    
     <notifications group="public" position="bottom center"  :style="{marginBottom:'2px'}"/>
    <handel-traning
      v-if="edit_Traning.isEdit || add_Traning"
      v-bind="{
        isEdit: edit_Traning.isEdit,
        editTitle: edit_Traning.editTitle,
        editDescription: edit_Traning.editDescription,
        editThumbnail: edit_Traning.editThumbnail,
      }"
      @handleClose="handleClose"
      @handelRequest="handelRequest"
    ></handel-traning>
    <confirm-delete
      v-if="deleteConfirm >= 0"
      v-bind="{ id: deleteConfirm, isOpen: true }"
      @handleClose="handleClose"
      @handelRequest="handelRequest"
    ></confirm-delete>

    <!-- table -->
    <v-row class="d-flex flex-column text-center">
      <v-col cols="12" class="mb-4">
        <h1 class="display-3 font-weight-bold mb-3 text-grey">
          {{ $t('trainingPaths.trainingPaths') }}
        </h1>
      </v-col>
      <v-col cols="12">
        <bact-table
          v-bind="{
            headers: headers,
            actionName: 'Traning/fetchTraning',
          }"
        >
          
          <v-btn
            class="ml-3 mb-3 mb-md-0 text-lg-h6"
            :color="primaryColor"
            dark
            @click="add_Traning = true"
          >
            <v-icon>{{ plusIcon }}</v-icon>
            {{ $t('trainingPaths.newPath') }}
          </v-btn>
          <template v-slot:action="{ data }">
            <v-btn
              text
              icon
              color="secondary lighten-2"
              @click="handelEdit(data)"
            >
              <v-icon color="green">{{ editIcon }}</v-icon>
            </v-btn>
            <v-btn
              text
              icon
              color="secondary lighten-2"
              @click="deleteConfirm = data.id"
            >
              <v-icon color="red">{{ deleteIcon }}</v-icon>
            </v-btn>
          </template>
        </bact-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BactTable from "./Components/traningBaseTable";
import HandelTraning from "./Components/dialogTraningInput";
import ConfirmDelete from "../../Components/confirmDelete";
import { mapActions } from "vuex";

export default {
  name: "Traning",

  components: { BactTable, HandelTraning, ConfirmDelete },

  data() {
    return {
      headers: [
        { text: this.$t('trainingPaths.title'), value: "title" },
        { text: this.$t('trainingPaths.description'), value: "description" },
        { text: "", value: "actions", align: "center" },
      ],
      add_Traning: false,
      deleteConfirm: -1,
      edit_Traning: {
        isEdit: false,
        editTitle: "",
        editTraning: "",
        editThumbnail: "",
        editId: "",
      },
    };
  },

  methods: {
    ...mapActions("Traning", ["addTraning", "editTraning", "delete"]),

    async handelRequest(data) {
      let mydata;
      if (data.method === "addTraning")
        mydata = { title: data.title, description: data.description,thumbnail:data.thumbnail };
      else if (data.method === "editTraning")
        mydata = {
          title: data.title,
          description: data.description,
          thumbnail: data.thumbnail,
          id: this.edit_Traning.editId,
        };
      else mydata = data.id;
      this.handleClose();
      await this[data.method](mydata);
    },

    handleClose() {
      this.add_Traning = false;
      this.edit_Traning = {
        isEdit: false,
        editTitle: "",
        editDescription: "",
        editThumbnail: "",
        editId: "",
      };
      this.deleteConfirm = -1;
    },
    handelEdit(data) {
      this.edit_Traning = {
        isEdit: true,
        editTitle: data.title,
        editDescription: data.description,
        editThumbnail:data.thumbnail,
        editId: data.id,
      };
    },
  },
};
</script>
