var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.change_Image)?_c('div',{style:({
        height: '200px',
        backgroundImage: _vm.getBackground,

        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        position: 'relative',
      })},[_c('div',{style:({ position: 'absolute', top: '0', left: '0' ,margin:'6px' })},[_c('base-button',{attrs:{"options":{ color: this.darkColor },"text":""},on:{"click":_vm.changeImage}},[_vm._v(" "+_vm._s(_vm.$t('trainingPaths.changeImage'))+" ")])],1)]):_vm._e(),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('v-card-title',{staticClass:"justify-center"},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.isEdit ? this.$t('trainingPaths.editPath') : this.$t('trainingPaths.newPath')))])]),_c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('trainingPaths.title'),"rules":_vm.titleRules},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('trainingPaths.description'),"rules":_vm.descriptionRules,"rows":"2","auto-grow":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-col',{staticClass:"mt-1",attrs:{"cols":"12"}},[(!_vm.change_Image)?[_c('v-file-input',{attrs:{"accept":"image/*","label":_vm.$t('image'),"prepend-icon":_vm.cameraIcon},model:{value:(_vm.thumbnail),callback:function ($$v) {_vm.thumbnail=$$v},expression:"thumbnail"}})]:_vm._e()],2)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('base-button',{attrs:{"options":{ color: this.grayBack50 },"text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(this.$t('form.cancel'))+" ")]),_c('base-button',{attrs:{"options":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t('form.save'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }