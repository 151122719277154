<template>
  <v-dialog persistent v-model="dialog" max-width="500px">
    <v-card>
      <div
        v-if="change_Image"
        :style="{
          height: '200px',
          backgroundImage: getBackground,

          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          position: 'relative',
        }"
      >
        <div :style="{ position: 'absolute', top: '0', left: '0' ,margin:'6px' }">
          <base-button
            :options="{ color: this.darkColor }"
            text
            @click="changeImage"
          >
            {{ $t('trainingPaths.changeImage') }}
          </base-button>
        </div>
      </div>

      <v-form ref="form" lazy-validation v-on:submit.prevent>
        <v-card-title class="justify-center">
          <span class="headline">{{
            isEdit ? this.$t('trainingPaths.editPath') : this.$t('trainingPaths.newPath')
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="title"
                  :label="$t('trainingPaths.title')"
                  :rules="titleRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="mt-1">
                <v-textarea
                  v-model="description"
                  :label="$t('trainingPaths.description')"
                  :rules="descriptionRules"
                  rows="2"
                  auto-grow
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="mt-1">
                <template v-if="!change_Image">
                  <v-file-input
                    accept="image/*"
                    :label="$t('image')"
                    :prepend-icon="cameraIcon"
                    v-model="thumbnail"
                  ></v-file-input>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <base-button
            :options="{ color: this.grayBack50 }"
            text
            @click="close"
          >
            {{ this.$t('form.cancel') }}
          </base-button>
          <base-button @click="save" options> {{ $t('form.save') }} </base-button>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseButton from "../../../../../core/Base/Buttons/BaseButton";
import { mapActions } from "vuex";
export default {
  name: "HandelQuestion",
  props: {
    isEdit: Boolean,
    editTitle: String,
    editDescription: String,
    editThumbnail: String,
  },
  data() {
    return {
      titleRules: [(v) => !!v || "العنوان مطلوب"],
      descriptionRules: [(v) => !!v || "الوصف مطلوب"],
      thumbnailRules: [(v) => !!v || "الصورة مطلوبة"],
      title: "",
      description: "",
      thumbnail: [],
      dialog: true,
      change_Image: false,
    };
  },
  methods: {
    ...mapActions("Traning", ["getImages"]),

    close() {
      this.dialog = false;
      (this.title = ""),
        (this.description = ""),
        (this.thumbnail = null),
        this.$refs.form.resetValidation();
      this.$emit("handleClose");
    },
    async save() {
      if (!this.$refs.form.validate()) return;

      let mydata = {
        title: this.title,
        description: this.description,
        method: this.isEdit ? "editTraning" : "addTraning",
      };
      if (typeof this.thumbnail !== "string")
        mydata = { ...mydata, thumbnail: this.thumbnail };
      console.log(mydata);
      this.$emit("handelRequest", mydata);
    },
    changeImage() {
      (this.thumbnail = []), (this.change_Image = false);
    },
  },
  computed: {
    getBackground() {
      return `url(https://bact-back.usol.ca/${this.thumbnail})`;
    },
  },

  created() {
    if (this.isEdit) {
      (this.title = this.editTitle),
        (this.description = this.editDescription),
        (this.thumbnail = this.editThumbnail);
      this.change_Image = true;
    }
  },

  components: { BaseButton },
};
</script>


<style scoped>
</style>